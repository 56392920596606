dmx.Attribute("text", "mounted", function (node, attr) {
  let _value = null;
  let _request = null;

  this.$watch(attr.value, value => {
    _value = value;
    if (!_request) {
      _request = requestAnimationFrame(() => {
        const text = _value != null ? String(_value) : "";
        node.innerText = text;
        _request = null;
      });
    }
  });
});
